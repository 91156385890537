.machine-container {
  display: flex;
  flex-direction: column;
}

.h1_planti {
  font-family: Poppins, sans-serif;
  color: #074d3c;
  font-weight: 600;
}

.h1_planti.mint {
  margin-bottom: 0px;
  font-size: 54px;
  line-height: 64px;
  font-weight: 500;
  text-align: left;
}

.h1_planti.barlow {
  margin-top: 0px;
  font-family: Barlow, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 100;
}

.h1_planti.small {
  margin-top: 56px;
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 32px;
}

.gif-container {
  display: flex;
  flex-direction: column;
}

.gif-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.5rem;
  justify-items: center;
  margin: 0;
  padding: 20px 0 20px 0;
}

.gif-grid .gif-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-self: center;
  align-self: center;
}

.gif-item img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}
